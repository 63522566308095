@tailwind base;
@tailwind components;
@tailwind utilities;


.faq-img, .about-img{
  background-image: url(../src/img/bg-faq.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
}
